<template>
  <div style="z-index:10; margin: 0px 0px;" :class="{ disabled }">
    <transition name="expand">
      <div v-if="open" class="email-alert">
        <span>Name this report</span>
        <span class="close-btn" data-tool-tip="Close" @click="open = false">
          <font-awesome-icon :icon="['far', 'times']" class="check-on checkbox-active" />
        </span>
        <div v-if="error" class="error">{{ error }}</div>
        <div>
          <div class="input-wrapper">
            <textarea
              id="reportName"
              ref="textarea"
              v-model="localReportName"
              :aria-describedby="isValidReportName ? '' : 'reportNameTooltip'"
              :maxlength="100"
              class="report-name-input"
              rows="1"
              @input="handleInput"
              @keypress="validateInput"
            ></textarea>
            <div class="tooltip-container">
              <font-awesome-icon
                id="reportNameTooltip"
                class="tooltip-icon"
                icon="fas fa-info-circle"
                @mouseenter="showTooltip = true"
                @mouseleave="showTooltip = false"
              />
              <div v-if="showTooltip" class="custom-tooltip">
                Only alphanumeric characters, dashes and underscores are allowed.
              </div>
            </div>
          </div>
          <div class="remaining-characters">{{ remainingCharactersText }}</div>
        </div>
        <div>
          <k-button :size="4" :label="saveLabel" :disabled="savingTemplate" @click="saveTemplate" />
        </div>
      </div>
    </transition>
    <div v-if="isLoading">
      <b-loading-spinner :width="20" class="txt-center" />
    </div>
    <div v-else class="flp-btn btn-size2 ex-dl-btn" @click="openCloseSaveTemplatePopup">
      Save Report
      <span class="flp-btn-icon-size2">
        <font-awesome-icon :icon="['fal', 'fa-cloud-upload']" />
      </span>
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { ERROR_MESSAGE_NAME_REQUIRED } from '@/constant';
import { get } from 'vuex-pathify';

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '@/components/elements/b-loading-spinner2.vue'
      ),
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    externalOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
    isValidReportName: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      errorMsg: null,
      savingTemplate: false,
      saveLabel: 'SAVE REPORT',

      localReportName: '',
      showTooltip: false,
    };
  },
  computed: {
    user: get('common/currentUser'),
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
    remainingCharacters() {
      return 100 - this.localReportName.length;
    },
    remainingCharactersText() {
      const remaining = this.remainingCharacters;
      return `${remaining} character${remaining < 2 ? '' : 's'} remaining`;
    },
  },
  watch: {
    externalOpen(n) {
      this.open = n;
      if (!n) {
        this.localReportName = '';
      }
    },
    savingTemplate(n) {
      if (n) {
        this.saveLabel = 'SAVING...';
      } else {
        this.saveLabel = 'SAVE REPORT';
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside, true);
    this.$nextTick(() => {
      this.adjustHeight(); // Trigger height adjustment after DOM updates
    });
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside, true);
  },
  methods: {
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
    saveTemplate() {
      if (this.validateTemplateName()) {
        this.$emit('save-template', this.localReportName);
        this.savingTemplate = true;
      }
    },
    validateTemplateName() {
      this.clearFormErrors();
      if (isBlank(this.localReportName?.trim())) {
        this.errorMsg = ERROR_MESSAGE_NAME_REQUIRED;
        this.$refs.textarea.classList.add('errorBox');
        this.savingTemplate = false;
        return false;
      }
      return true;
    },
    clearFormErrors() {
      this.errorMsg = null;
      this.$refs.textarea?.classList.remove('errorBox');
    },
    openCloseSaveTemplatePopup() {
      this.open = !this.open;
      this.savingTemplate = false;
      this.$nextTick(() => {
        this.adjustHeight(); // Trigger height adjustment after DOM updates
      });
      if (this.errorMsg) {
        this.clearFormErrors();
        this.localReportName = '';
      }
    },
    handleInput(event) {
      this.localReportName = event.target.value; // Update localReportName
      this.adjustHeight(event); // Adjust height of the textarea
      this.validateInput(event); // Validate input
    },
    adjustHeight(event) {
      const textarea = event ? event.target : this.$refs.textarea; // If event exists, use target, otherwise use refs
      if (textarea) {
        textarea.style.height = 'auto'; // Reset height to auto to calculate new height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
      }
    },
    validateInput(event) {
      this.clearFormErrors();
      const regex = /^[a-zA-Z0-9-_ ]*$/; // Alphanumeric, dashes, underscores, and spaces
      if (!regex.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault(); // Prevent invalid input
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.errorBox {
  border: 1px solid #fc4242 !important;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.close-btn {
  float: right;
}
.ex-dl-btn {
  color: #303335;
}
.expand-enter-active,
.expand-leave-active {
  -webkit-transition: top 0.3s ease, opacity 0.3s ease;
  -moz-transition: top 0.3s ease, opacity 0.3s ease;
  -ms-transition: top 0.3s ease, opacity 0.3s ease;
  -o-transition: top 0.3s ease, opacity 0.3s ease;
  transition: top 0.3s ease, opacity 0.3s ease;
}

.expand-enter,
.expand-leave-to {
  top: 100% !important;
  opacity: 0 !important;
}

.progress100 {
  width: 100% !important;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }
  .email-alert {
    position: absolute;
    top: 15%;
    left: 76%;
    width: 300px;
    padding: 16px 30px;
    color: #fff;
    // text-align: center;
    background-color: rgba(29, 33, 37, 0.95);
    border-radius: 5px;
    transform: translateX(-20%);
    z-index: 10;

    .success {
      font-size: 13px;
      color: rgba(107, 207, 24);
    }
    .fail {
      font-size: 13px;
      color: rgba(252, 66, 66);
    }

    input[type='email'] {
      width: 230px;
      margin: 0.5rem 0;
      font-size: 14px;
      line-height: 33px;
      border-radius: 0.15rem;
    }
  }
  .flp-btn {
    position: relative;
    display: inline-block;
    border-radius: 6px !important;
    padding: 7px 12px 8px !important;
    font-size: 12px;
    font-weight: 400;
    color: var(--buttontext) !important;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    cursor: pointer;
    user-select: none;
    background-color: var(--primarycolor) !important;
    -webkit-border-radius: 0;
    border-radius: 3px;
    transition: all 0.6s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-touch-callout: none;
  }
  .btn-size2 {
    min-width: 20px;
    padding: 11px;
    padding-left: 17px;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      color: #fff !important;
      background-color: #424242 !important;
      border: none !important;
    }
  }
  .flp-btn-icon-size2 {
    position: relative;
    top: 2px;
    padding: 5px 4px 4px 12px;
    margin: 0 0 0 13px;
    font-size: 18px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
  }
  .hide {
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

.alt-loader {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  margin: 11px 0px 2px 0px;
}
.alt-loader-bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}
.alt-loader-progress {
  position: absolute;
  z-index: 3;
  width: 1%;
  height: 4px;
  background-color: #00abb7;
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -ms-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}
.fp-text-link {
  font-size: 12px;
  color: #707e8a;
  display: inline-block;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  & p {
    text-decoration: underline;
    cursor: pointer;
  }
  & p:hover {
    color: var(--primarycolor) !important;
  }
  & span {
    text-decoration: none;
    margin-left: 8px;
    color: #212325 !important;
    .advance-filter-clear-icon:hover {
      color: var(--primarycolor) !important;
    }
  }
  & span:hover {
    color: #cad1d6;
  }
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center; /* Align items vertically */
}

.report-name-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide overflow */
  transition: height 0.2s ease; /* Smooth height transition */
}

.remaining-characters {
  margin-top: 5px; /* Space between input and character count */
  font-size: 12px; /* Smaller font for character count */
  color: #5a5a5a; /* Neutral gray for text */
}

.tooltip-container {
  display: flex;
  align-items: center; /* Align tooltip icon and text */
  margin-left: 5px; /* Space between textarea and tooltip */
}

.tooltip-icon {
  margin-left: 5px;
  color: #5a5a5a; /* Neutral gray for icon */
  cursor: pointer;
}

.custom-tooltip {
  position: absolute;
  top: -45px; /* Position above the input */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(245, 245, 250, 1); /* White background */
  color: #333; /* Dark text color */
  padding: 8px 12px;
  border-radius: 3px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  text-align: center;
}

.custom-tooltip.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in */
}

.txt-center {
  text-align: center;
  z-index: 99 !important;
  max-width: 20px !important;
  padding-right: 100px !important;
  padding-bottom: 50px !important;
  max-height: 10px !important;
}
</style>
